import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import Modal from 'react-modal'; // Для работы с модальными окнами
import classes from "./../styles/AdminInput.module.css";
import * as XLSX from 'xlsx';


Modal.setAppElement('#root'); // Не забудьте установить root для модальных окон

const AdminInput = ({setWrapperWidth, swiperRef}) => {
    const [phones, setPhones] = useState([]);
    const [selectedPhone, setSelectedPhone] = useState(null); // Для модалки редактирования
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isNewModalOpen, setIsNewModalOpen] = useState(false);
    const scrollContainerRef = useRef(null);
    const [erMod, setErMod] = useState('');

    const handleMouseEnter = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.mousewheel.disable(); // Отключаем прокрутку Swiper'а
        }
    };

    const handleMouseLeave = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.mousewheel.enable(); // Включаем прокрутку Swiper'а
        }
    };

    // Для мобильных устройств отключаем Swiper при касании и активируем обратно после
    const handleTouchStart = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.allowTouchMove = false; // Отключаем скролл Swiper'а на touch
        }
    };

    const handleTouchEnd = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.allowTouchMove = true; // Включаем скролл Swiper'а обратно
        }
    };

    // Функция для получения данных с API
    const fetchPhones = async () => {
        try {
            const response = await axios.get(`https://sms.silenc.ru/api/phone/?status=verified`);
            setPhones(response.data);
        } catch (error) {
            console.error("Ошибка при получении данных:", error);
        }
    };

    useEffect(() => {
        fetchPhones();
        setWrapperWidth('80vw');
    }, [setWrapperWidth]);

    // Открытие модалки редактирования
    const openEditModal = (phone) => {
        setSelectedPhone(phone);
        setIsEditModalOpen(true);
    };

    // Закрытие модалки редактирования
    const closeEditModal = () => {
        setIsEditModalOpen(false);
        setSelectedPhone(null);
    };

    // Обработчик для сохранения изменений
    const handleSaveChanges = async () => {
        try {
            await axios.patch(`https://sms.silenc.ru/api/phone/${selectedPhone.id}/`, {
                firstname: selectedPhone.firstname,
                lastname: selectedPhone.lastname,
                patronymic: selectedPhone.patronymic,
                answer: selectedPhone.answer
            });
            fetchPhones(); // Обновляем список после сохранения
            closeEditModal();
        } catch (error) {
            console.error("Ошибка при сохранении данных:", error);
        }
    };

    // Открытие модалки создания новой записи
    const openNewModal = () => {
        setIsNewModalOpen(true);
    };

    // Закрытие модалки создания
    const closeNewModal = () => {
        setIsNewModalOpen(false);
    };

    // Создание новой записи
    const handleCreateNew = async (newPhone) => {
        setErMod('');
        if (selectedPhone.number.length === 0) {
            setErMod('Введите любые цифры в поле Контакт');
        } else if ((selectedPhone.firstname.replace(' ', '').length + selectedPhone.lastname.replace(' ', '').length + selectedPhone.patronymic.replace(' ', '').length) === 0) {
            setErMod('ФИО обязательно, хотя бы фамилию и имя');
        }
        try {
            await axios.post(`https://sms.silenc.ru/api/phone/`, newPhone);
            fetchPhones(); // Обновляем список после добавления
            closeNewModal();
        } catch (error) {
            console.error("Ошибка при создании новой записи:", error);
        }
    };

    // Функция для скачивания списка гостей в Excel
    const downloadExcel = () => {
        try {
            const worksheet = XLSX.utils.json_to_sheet(phones.map((phone, i) => ({
                '№': i+1,
                'Контакт': phone.number || '',
                'Имя': phone.firstname || '',
                'Фамилия': phone.lastname || '',
                'Отчество': phone.patronymic || '',
                'Ответ': phone.answer === 'true' ? 'Приду' : 'Не приду'
            })));
            // Устанавливаем ширину для столбцов
            worksheet['!cols'] = [
                { wch: 5 },  // Номер
                { wch: 20 }, // Контакт
                { wch: 20 }, // Имя
                { wch: 20 }, // Фамилия
                { wch: 20 }, // Отчество
                { wch: 10 }  // Ответ
            ];
            // Устанавливаем высоту строк для всех строк
            const rowHeight = 20; // Задаем высоту строк
            worksheet['!rows'] = Array(phones.length + 1).fill({ hpx: rowHeight });
            // Применяем стили к заголовкам (например, увеличим размер шрифта и центрируем текст)
            const headers = ['A1', 'B1', 'C1', 'D1', 'E1']; // Ячейки заголовков
            headers.forEach(cell => {
                worksheet[cell].s = {
                    font: { sz: 14, bold: true }, // Увеличенный жирный шрифт
                    alignment: {
                        horizontal: 'center', // Центрирование по горизонтали
                        vertical: 'center'     // Центрирование по вертикали
                    }
                };
            });
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Гости");
            XLSX.writeFile(workbook, "Список_гостей.xlsx");
        } catch (e) {console.log(e)}
    };


    return (
        <div className={classes.adminPage}>
            <div>
                <h1>Список гостей</h1>
                Скачать Excel файл
                <button className={classes.dwnBtn} onClick={downloadExcel}></button>
            </div>
            <button onClick={openNewModal} className={classes.newGuest}>Создать новую запись</button>
            <div className={classes.contGuests} ref={scrollContainerRef}
                 onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
                 onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}
            >
                <ul className={classes.listGuests}>
                    {phones.map(phone => (
                        <li key={phone.id} className={classes.liGuest}>
                            <p>Контакт: {phone.number}</p>
                            <p>ФИО: {phone.firstname} {phone.lastname} {phone.patronymic}</p>
                            <p style={{textTransform: 'uppercase'}}>Ответ: {phone.answer === 'true' ? 'Приду' : 'Не приду'}</p>
                            <button onClick={() => openEditModal(phone)} className={classes.editBtn}>Редактировать
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            {/* Модалка редактирования */}
            <Modal isOpen={isEditModalOpen} onRequestClose={closeEditModal}>
                {selectedPhone && (
                    <div className={classes.editContainer}>
                        <h2>Редактирование записи</h2>
                        <label>{selectedPhone.number}</label>
                        <div className={classes.containModal}>
                            <label>ФИО:</label>
                            <input
                                type="text"
                                value={selectedPhone.firstname}
                                onChange={(e) => setSelectedPhone({...selectedPhone, firstname: e.target.value})}
                                placeholder="Имя"
                            />
                            <input
                                type="text"
                                value={selectedPhone.lastname}
                                onChange={(e) => setSelectedPhone({...selectedPhone, lastname: e.target.value})}
                                placeholder="Фамилия"
                            />
                            <input
                                type="text"
                                value={selectedPhone.patronymic}
                                onChange={(e) => setSelectedPhone({...selectedPhone, patronymic: e.target.value})}
                                placeholder="Отчество"
                            />
                            Ответ:
                            <select
                                value={selectedPhone.answer}
                                onChange={(e) => setSelectedPhone({...selectedPhone, answer: e.target.value})}
                            >
                                <option value="true">Приду</option>
                                <option value="false">Не приду</option>
                            </select>
                            <button onClick={handleSaveChanges}>Сохранить</button>
                            <button onClick={closeEditModal}>Закрыть</button>
                        </div>
                    </div>
                )}
            </Modal>

            {/* Модалка для создания новой записи */}
            <Modal isOpen={isNewModalOpen} onRequestClose={closeNewModal}>
                <div className={classes.editContainer}>
                    <h2>Создание новой записи</h2>
                    <label className={classes.error}>{erMod}</label>
                    <div className={classes.containModal}>
                        <label>Контакт:</label>
                        <input
                            type="decimal"
                            placeholder="Контакт"
                            onChange={(e) => setSelectedPhone({...selectedPhone, number: e.target.value})}
                        />
                        <label>ФИО:</label>
                        <input
                            type="text"
                            placeholder="Имя"
                            onChange={(e) => setSelectedPhone({...selectedPhone, firstname: e.target.value})}
                        />
                        <input
                            type="text"
                            placeholder="Фамилия"
                            onChange={(e) => setSelectedPhone({...selectedPhone, lastname: e.target.value})}
                        />
                        <input
                            type="text"
                            placeholder="Отчество"
                            onChange={(e) => setSelectedPhone({...selectedPhone, patronymic: e.target.value})}
                        />
                        Ответ:
                        <select
                            value="true"
                            onChange={(e) => setSelectedPhone({...selectedPhone, answer: e.target.value})}
                        >
                            <option value="true">Приду</option>
                            <option value="false">Не приду</option>
                        </select>
                        <button onClick={handleCreateNew}>Создать</button>
                        <button onClick={closeNewModal}>Закрыть</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default AdminInput;