import React, {useState} from 'react';
import './../styles/AboutW.css'
import Plan from "./Plan";
import Dress from "./Dress";

const AboutW = () => {
    const [isModalVisible, setModalVisible] = useState(false);

    const showModal = () => setModalVisible(true);
    const hideModal = () => setModalVisible(false);

    return (
        <div>
            <div className="aboutw__content">
                <p className="aboutw__text">
                    О Вашем присутствии или участии просьба сообщить заранее до 20.09.2024 (пятница) 20:00
                </p>
                <button className="show-modal-btn" onClick={showModal}>Цветовая палитра для Вас!</button>
                <div className="plot">
                    <Plan/>
                </div>
            </div>
            {isModalVisible && (
                <div className="modal show" onClick={hideModal}>
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="close-modal-btn" onClick={hideModal}>
                            &times; {/* Символ "крестик" для закрытия */}
                        </button>
                        <div className="dress__modal">
                            <Dress color_name={'birus'}/>
                            <Dress color_name={'orange'}/>
                            <Dress color_name={'red'}/>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AboutW;