import React, {useRef} from "react";
import './App.css';
import {Swiper, SwiperSlide} from "swiper/react";
import {Keyboard, Mousewheel, Parallax, Pagination, Scrollbar} from "swiper/modules";
import Header from "./components/Header";
import WhenComp from "./components/WhenComp";
import History from "./components/History";
import AboutW from "./components/AboutW";
import Invitation from "./components/Invitation";
import Map from "./components/Map";
import {LoginProvider} from "./context/LoginContext";

function App() {
    const swiperRef = useRef(null);

    return (
        <LoginProvider>
            <div className="wrapper">
                <Header swiperRef={swiperRef}/>
                <div className="page">
                    <Swiper
                        ref={swiperRef}
                        direction={'vertical'}
                        slidesPerView={1}
                        parallax={true}
                        keyboard={{
                            enabled: true, onlyInViewport: true, pageUpDown: true,
                        }}
                        mousewheel={{
                            sensitivity: 1,
                        }}
                        watchOverflow={true}
                        speed={800}
                        observer={true}
                        observeParents={true}
                        observeSlideChildren={true}
                        pagination={{
                            el: '.page__pagination',
                            clickable: true,
                            bulletClass: 'page__bullet',
                            bulletActiveClass: 'page__bullet_active',
                        }}
                        scrollbar={{
                            el: '.page__scroll', dragClass: 'page__drag-scroll', draggable: true,
                        }}
                        modules={[Keyboard, Mousewheel, Parallax, Pagination, Scrollbar]}
                        className="page__wrapper"
                        init={false} // Set init to false
                        on={{
                            init: () => swiperRef.current?.swiper.on('init', () => {
                            }), // Custom logic
                            slideChange: () => swiperRef.current?.swiper.on('slideChange', () => {
                            }), // Custom logic
                        }}
                    >
                        <SwiperSlide className="page__screen screen">
                            <WhenComp swiperRef={swiperRef}/> {/*when*/}
                        </SwiperSlide>

                        <SwiperSlide className="page__screen screen">
                            <History/> {/*history*/}
                        </SwiperSlide>

                        <SwiperSlide className="page__screen screen">
                            <Invitation/> {/*invitation*/}
                        </SwiperSlide>

                        <SwiperSlide className="page__screen screen">
                            <AboutW/> {/*about w*/}
                        </SwiperSlide>

                        <SwiperSlide className="page__screen screen">
                            <Map swiperRef={swiperRef}/> {/*where*/}
                        </SwiperSlide>
                    </Swiper>
                    <div className="page__pagination"></div>
                    <div className="page__scroll"></div>
                </div>
            </div>
        </LoginProvider>
    );
}

export default App;
