import React, {useEffect, useRef} from 'react';
import './../styles/YandexMap.css';
import locIconMix from './../assets/loc_transparent.png';

const YandexMap = () => {
    const mapContainerRef = useRef(null);  // Используем useRef для ссылки на контейнер карты

    useEffect(() => {
        const loadYandexMap = () => {
            if (window.ymaps && mapContainerRef.current) {
                window.ymaps.ready(() => {
                    if (mapContainerRef.current) {
                        const map = new window.ymaps.Map(mapContainerRef.current, {
                            center: [56.838026, 35.882602], // Координаты центра (Москва) 55.751574, 37.573856
                            zoom: 16,                       // Масштаб карты
                            controls: ['zoomControl', 'fullscreenControl'] // Контролы карты
                        });

                        // Добавление метки (Placemark) с координатами
                        const placemark = new window.ymaps.Placemark([56.838026, 35.882602], {
                            balloonContent: 'Здесь проходит свадьба!',
                            hintContent: 'Свадебная локация'
                        }, {
                            iconLayout: 'default#image',  // Используем кастомное изображение
                            iconImageHref: locIconMix,  // Путь к изображению (например, сердце или кольца)
                            iconImageSize: [40, 40],      // Размер иконки
                            iconImageOffset: [-20, -45],  // Сдвиг иконки (чтобы центрировать её относительно точки)
                        });

                        // Добавляем метку на карту
                        map.geoObjects.add(placemark);
                    }
                });
            }
        };

        // Загружаем API Яндекс.Карт, если он не загружен
        if (!window.ymaps) {
            const script = document.createElement('script');
            script.src = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=ba29c1b3-da24-4924-a915-6b54dd251ea7'; // Укажите API ключ
            script.onload = loadYandexMap;
            document.body.appendChild(script);
        } else {
            loadYandexMap(); // Если API уже загружен
        }
    }, []);

    return (
        <div className="yandex-map-container">
            <h2>Местоположение:</h2>
            <div ref={mapContainerRef} className="yandex-map"></div>
        </div>
    );
};

export default YandexMap;
