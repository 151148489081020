import React, {createContext, useState} from 'react';


export const LoginContext = createContext();

export const LoginProvider = ({children}) => {
    const [isLogin, setIsLogin] = useState(false);
    const [socialData, setSocialData] = useState([]);
    const [isLogout, setIsLogout] = useState(false);
    const [isAdmin, setIsAdmin] = useState('');
    const [moment, setMoment] = useState(true);

    return (
        <LoginContext.Provider value={{
            isLogin, setIsLogin, socialData, setSocialData,
            isLogout, setIsLogout, isAdmin, setIsAdmin, moment, setMoment
        }}>
            {children}
        </LoginContext.Provider>
    );
};
