import React, {useState, useEffect, useContext} from 'react';
import Cookies from 'js-cookie'; // Импортируем js-cookie для работы с куками
import './../styles/FeedBackBtn.css';
import {LoginContext} from "../context/LoginContext";

const FeedBackBtn = ({ swiperRef }) => {
    const [isClicked, setIsClicked] = useState(false);
    const [buttonText, setButtonText] = useState('мой ответ');
    const {isAdmin, isLogout, isLogin, moment} = useContext(LoginContext);

    // Проверка наличия куков при загрузке компонента
    useEffect(() => {
        console.log(isLogin, moment);
        if (isAdmin) {
            setButtonText('гости'); // Меняем текст кнопки на "Map"
        } else if (moment) {
            setButtonText('трансляция'); // Меняем текст кнопки на "Map"
        } else if (isLogin && !moment) {
            setButtonText('карта'); // Меняем текст кнопки на "Map"
        } else {
            setButtonText('мой ответ');
        }
    }, [isLogout, isLogin]);

    const handleClick = () => {
        setIsClicked(true);

        setTimeout(() => {
            setIsClicked(false); // Убираем класс через некоторое время после клика
        }, 1000); // Задержка для плавного исчезновения эффекта

        // Переход на слайд с индексом 4 (Map)
        if (swiperRef.current) {
            swiperRef.current.swiper.slideTo(4);
        }
    };

    return (
        <div className="wrapperFB">
            <a href="#" className={isClicked ? 'AFactive' : ''} onClick={handleClick}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                {buttonText}
            </a>
        </div>
    );
};

export default FeedBackBtn;