// components/Notification.js
import React from 'react';

const Notification = ({ notification }) => {
    return (
        notification && (
            <div className={`notification ${notification ? 'show' : ''}`}>
                {notification}
            </div>
        )
    );
};

export default Notification;
