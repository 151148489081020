import React from 'react';
import '../styles/History.css';

const History = () => {
    return (
        <div>
            <div className="history__block">
                <h2 className="history__title">История Бобоевых только начинается</h2>
                <div className="history__content">
                    <p className="p_history">Мини хронология:</p>
                    <div className="history">
                        <ul className="history__items">
                            <li>*24.11.2019 - Первая встреча</li>
                            <li>*14.04.2023 - Первое общее служение</li>
                            <li>*20.08.2022 - Молитва о браке</li>
                            <li>*04.08.2024 - Предложение</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default History;