import React, {useEffect, useRef, useState} from 'react';
import posterStart from './../assets/st.jpg';
import posterEnd from './../assets/en.jpg';
import classes from "./../styles/Player.module.css";
import Hls from "hls.js";


const Player = () => {
    const videoRef = useRef(null);
    const [videoSrc, setVideoSrc] = useState('https://stream.novoe-serdce.ru/hls/wedding.m3u8');
    const [posterImage, setPosterImage] = useState(posterStart);

    useEffect(() => {
        const video = videoRef.current;

        if (Hls.isSupported()) {
            const hls = new Hls();
            hls.loadSource(videoSrc);
            hls.attachMedia(video);

            hls.on(Hls.Events.ERROR, (event, data) => {
                if (data.fatal) {
                    // Если трансляция недоступна, подставляем локальный файл
                    const fallbackSrc = '/hls/output.m3u8';
                    setVideoSrc(fallbackSrc);

                    if (video) {
                        video.src = fallbackSrc;
                        video.load();
                    }
                }
            });
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = videoSrc;
            video.load();
        }
    }, [videoSrc]);

    const handleEnded = () => {
        // Подмена источника и установка постера при завершении воспроизведения
        const newSrc = 'https://05.102024.ru/hls/output.m3u8';
        setVideoSrc(newSrc);
        setPosterImage(posterEnd);

        if (videoRef.current) {
            videoRef.current.load();
        }
    };


    return (
        <div className={classes.playerContainer}>
            <video
                ref={videoRef}
                controls
                onEnded={handleEnded}
                src={videoSrc}
                poster={posterImage}
                width="100%"
            />
            <p className={classes.compactText}>Трансляция на <br/><a href="https://www.youtube.com/watch?v=b2qYu6bNM8Q">Youtube</a></p>
        </div>
    );
};

export default Player;
