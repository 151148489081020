import React, {useContext, useEffect, useState} from 'react';
import classes from "./../styles/PhoneInput.module.css";
import Cookies from "js-cookie";
import SocialButtons from "./SocialButtons";
import {LoginContext} from "../context/LoginContext";

const PhoneInput = ({error, setError, setLoading, setHash, updateStage, setFio}) => {
    const [phone, setPhone] = useState('');
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const checkPhone = ['9301792376', '9108466475', '9040045458', '9108486909', '9520626505', '9157081493', '9040270943', '9040042468', '9641669802', '9206903989', '9201967975', '9001179405', '9520621863', '9043504301', '9201512019', '9108478946', '9038004873', '9040299685', '9201744116', '9607032483', '9040045459', '9611411029', '9680265955', '9040270943', '9657248968'];
    const [smsPack, setSmsPack] = useState(false);
    const {isLogin} = useContext(LoginContext);

    useEffect(() => {
        // const numberCookie = Cookies.get('user_phone');
        // if (numberCookie) {
        //     setPhone(numberCookie);
        // }
        if (Cookies.get('sms_pack')) {
            setSmsPack(true);
            setError('Смс пакет закончился, попробуйте позже или используйте телеграм или гугл')} else {setSmsPack(false)}
    }, []);

    useEffect(() => {
        if (isLogin) {
            Cookies.remove('sms_pack');
            setSmsPack(false);
        }
    }, [isLogin]);

    const handlePhoneChange = (e) => {
        const value = e.target.value;

        // Разрешить только цифры
        if (/^\d*$/.test(value) && value.length <= 10) {
            setPhone(value);
        }

        // Меняем состояние в зависимости от длины номера
        if (value.length < 10) {
            setIsPhoneValid(false); // Номер невалиден, если меньше 10 символов
        } else if (value.length === 10) {
            setIsPhoneValid(true);  // Номер валиден, если 10 символов
            setError('');
        }
    };

    const handlePhoneSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        if (isPhoneValid) {
            if (checkPhone.includes(phone)) {
                Cookies.set('sms_pack', 15, {expires: 180});
                setError('Смс пакет закончился, попробуйте позже или используйте телеграм или гугл');
                setSmsPack(true);
                setLoading(false);
            } else {
                // Запрос на бэкенд для отправки кода
                try {
                    const response = await fetch('https://sms.silenc.ru/api/phone/', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json' // Указываем тип содержимого
                        },
                        body: JSON.stringify({number: `+7${phone}`})
                    });

                    const answerPhone = await response.json();
                    setHash(answerPhone.id);
                    Cookies.set('user_phone', phone, {expires: 180})
                    if (answerPhone.status) {
                        if (answerPhone.lastname || answerPhone.firstname || answerPhone.patronymic) {
                            let nf = `${answerPhone.lastname} ${answerPhone.firstname} ${answerPhone.patronymic}`.trim();
                            // setHasFio(nf);
                            Cookies.set('user_fio', nf, {expires: 180}); // Сохраняем fio в куки на полгода (180 дней)
                        }
                        if (answerPhone.answer) {
                            Cookies.set('user_answer', String(answerPhone.answer), {expires: 180})
                        }
                        updateStage(2, 2);  // Переход к этапу ввода кода
                    } else {
                        setError('Ошибка при отправке. Попробуйте ещё раз.');
                    }
                } catch (error) {
                    setError(`Ошибка сети. Попробуйте ещё раз.${error}`);
                } finally {
                    setLoading(false);
                }
            }
        } else {
            setError('только мобильные номера(10 цифр)');
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handlePhoneSubmit}>
            <h1>Введите номер телефона</h1>
            <div className={classes.input_phone}>
                <input
                    type="text"
                    className={classes.country}
                    disabled={true}
                    value={'+7'}
                />
                <input
                    type="text"
                    placeholder={"номер телефона"}
                    className={`${classes.phone} ${isPhoneValid ? classes['validLenght'] : ''}`}
                    value={phone}
                    onChange={handlePhoneChange}
                    disabled={smsPack}
                />
            </div>
            {error && <p className={classes.error}>{error}</p>}
            <button type="submit" className={classes.btn_send_sms} style={{ display: smsPack ? 'none' : 'inline-block' }}>Получить код</button>
            <SocialButtons setError={setError} updateStage={updateStage}
                           setLoading={setLoading} setHash={setHash} setFio={setFio}/>
        </form>
    );
};

export default PhoneInput;
