import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {GoogleOAuthProvider} from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <GoogleOAuthProvider clientId="1041931603475-c7ej3fpf2mr47856nqgjmaj9f9fm3t0v.apps.googleusercontent.com">
        <App/>
    </GoogleOAuthProvider>
    // </React.StrictMode>
);
