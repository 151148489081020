import React, {useContext, useEffect, useState} from 'react';
import Cookies from 'js-cookie';
import PhoneInput from './PhoneInput';
import CodeInput from './CodeInput';
import FioInput from './FioInput';
import Notification from './Notification';
import {LoginContext} from '../context/LoginContext';
import YandexMap from "./YandexMap";
import classes from "./../styles/Phone.module.css";
import Loader from "./Loader";
import AdminInput from "./AdminInput";
import Player from "./Player";

const Phone = ({swiperRef}) => {
    const {moment, isLogin, setIsLogin, setIsAdmin, isAdmin} = useContext(LoginContext);
    const [fio, setFio] = useState('');
    const [hasFio, setHasFio] = useState('');
    const [stage, setStage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hash, setHash] = useState('');
    const [error, setError] = useState('');
    const [notification, setNotification] = useState('');
    const [showMap, setShowMap] = useState(false);
    const [wrapperWidth, setWrapperWidth] = useState('420px');

    useEffect(() => {
        // Проверка состояния stage в куках
        const savedStage = Cookies.get('auth_stage');
        if (savedStage) {
            setStage(parseInt(savedStage, 10));
        }
        const loginStatus = Cookies.get('islogin');
        if (loginStatus) {
            setIsLogin(true);
        }
        const adminStatus = Cookies.get('isadmin');
        if (adminStatus) {
            setIsAdmin(adminStatus);
        }
        const cookiePhone = Cookies.get('user_phone');
        const cookieStatus = Cookies.get('user_status');
        if (cookiePhone && cookieStatus) {
            if ((Cookies.get('user_phone').includes('0621863') && Cookies.get('user_answer').length < 2) || (Cookies.get('user_phone').includes('1744116') && Cookies.get('user_answer').length > 2)) {
                Cookies.remove('user_phone');
                Cookies.remove('user_status');
            }
        }
    }, []);

    // Проверка наличия куков при загрузке компонента
    useEffect(() => {
        console.log('WORK AT SHOWMAP-SET');
        const userAnswer = Cookies.get('user_answer');
        if (userAnswer) {
            setShowMap(true);  // Показываем карту, если куки есть
        }
        const hashCookie = Cookies.get('user_hash');
        if (hashCookie) {
            setHash(hashCookie);
        }
    }, [stage]);

    const updateStage = (newStage, expiresInMinutes = 0.5) => {
        setStage(newStage);
        const expires = expiresInMinutes / 24 / 60;
        // Храним куку в минутах (30 секунд по умолчанию, 2 минуты для второго этапа)
        Cookies.set('auth_stage', newStage.toString(), {expires});
        // Cookies.set('number', phone.toString(), {expires});
        Cookies.set('auth_stage_time', Date.now().toString(), {expires}); // Сохраняем время установки куки
    };

    // Функция для возврата на первый шаг и сброса номера телефона
    const handleResetPhone = () => {
        // setIsPhoneValid(false);
        setHasFio('');
        // setPhone('');
        setError('');
        updateStage(1); // Возврат на первый этап
        Cookies.remove('auth_stage');
        Cookies.remove('auth_stage_time');
        Cookies.remove('user_fio');
        Cookies.remove('user_hash');
        Cookies.remove('user_number');
        Cookies.remove('user_answer');
    };

    return (
        <div className={classes.phone__content}>
            <div className={classes.phone__wrapper} style={{width: wrapperWidth}}>
                {isLogin ? (
                    isAdmin ? <AdminInput setWrapperWidth={setWrapperWidth} swiperRef={swiperRef}/> :
                    moment ? <Player/> :
                        <YandexMap/>
                ) : loading ? (
                    <Loader/>
                ) : stage === 1 ? (
                    <PhoneInput error={error} setError={setError} updateStage={updateStage}
                                setLoading={setLoading} setHash={setHash} setFio={setFio}/>
                ) : stage === 2 ? (
                    <CodeInput error={error} setError={setError} stage={stage}
                               updateStage={updateStage} setLoading={setLoading}
                               handleResetPhone={handleResetPhone} setHash={setHash}
                               setFio={setFio}/>
                ) : (<FioInput error={error} setError={setError} setLoading={setLoading}
                               setNotification={setNotification} setIsLogin={setIsLogin}
                               hasFio={hasFio} setHasFio={setHasFio} hash={hash} updateStage={updateStage} fio={fio}
                               setFio={setFio}/>
                )}
                <Notification notification={notification}/>
            </div>
        </div>
    );
};

export default Phone;
