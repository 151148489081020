import React from 'react';
import './../styles/Loader.css';

const Loader = ({ width = '180px', height = '180px' }) => {
    return (
        <div className="loader__content">
            <div className="loader">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    );
};

export default Loader;