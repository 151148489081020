import React from 'react';
import '../styles/Invitation.css'

const Invitation = () => {
    return (
        <div>
            <div className="invitation__content">
                <h2 className="invitation__title">Дорогой читатель!</h2>
                <div className="invitation_text">
                    <p>
                        Приглашаем Вас на торжественное венчание и банкет 05.10.2024 (суббота) по адресу: г.Тверь
                        ул.
                        1-ая
                        Желтиковская д.14
                    </p>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <p>
                        Мы будем рады вашим улыбкам вместо букетов цветов, они запомнятся и украсят богаче наш день,
                        и
                        легкому подарку в конверте, который поможет нам осуществить нашу мечту о своем жилище.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Invitation;