import React, {useContext, useEffect} from 'react';
import './../styles/Header.css';
import {googleLogout} from "@react-oauth/google";
import Cookies from "js-cookie";
import {LoginContext} from "../context/LoginContext";

const Header = ({swiperRef}) => {
    const {setIsAdmin, isAdmin, isLogin, setIsLogin, setSocialData, setIsLogout, moment} = useContext(LoginContext);

    useEffect(() => {
        const userAnswer = Cookies.get('user_answer');
        if (userAnswer) {
            setIsLogin(true);
        }
    }, []);

    useEffect(() => {
        if (!swiperRef.current) return;

        const pageSlider = swiperRef.current.swiper;
        const menuLinks = document.querySelectorAll('.menu__link');
        const wrapper = document.querySelector('.wrapper');
        const menuEl = document.querySelector('.header__menu');
        const burgerEl = document.querySelector('.header__burger');
        const bodyEl = document.querySelector('body');
        burgerEl.addEventListener("click", (e) => {
            menuEl.classList.toggle('active');
            burgerEl.classList.toggle('active');
            bodyEl.classList.toggle('lock');
        });
        menuEl.addEventListener("click", (e) => {
            menuEl.classList.toggle('active', false);
            burgerEl.classList.toggle('active', false);
            bodyEl.classList.toggle('lock', false);
        });
        wrapper.classList.add('_loaded');

        function sliderInit() {
            if (menuLinks.length > 0) {
                menuLinks[pageSlider.realIndex].classList.add('_active');
                menuLinks.forEach((menuLink, index) => {
                    menuLink.addEventListener("click", (e) => {
                        e.preventDefault();
                        pageSlider.slideTo(index, 800);
                        // menuLink.classList.add('_active');
                    });
                });
            }
        }

        sliderInit();

        const menuSliderRemove = () => {
            menuLinks.forEach(link => link.classList.remove('_active'));
            menuEl.classList.remove('active');
            burgerEl.classList.remove('active');
            bodyEl.classList.remove('lock');
        };

        pageSlider.on('slideChange', () => {
            menuSliderRemove();
            menuLinks[pageSlider.realIndex].classList.add('_active');
        });

        pageSlider.init();

        // Cleanup event listeners on component unmount
        return () => {
            pageSlider.off('slideChange');
            menuLinks.forEach((menuLink, index) => {
                menuLink.removeEventListener('click', () => pageSlider.slideTo(index, 800));
            });
        };
    }, [swiperRef]);

    const accLogout = () => {
        googleLogout();
        Cookies.remove('auth_stage');
        Cookies.remove('auth_stage_time');
        Cookies.remove('user_fio');
        Cookies.remove('user_hash');
        Cookies.remove('user_phone');
        Cookies.remove('user_answer');
        Cookies.remove('user_status');
        Cookies.remove('sms_pack');
        setIsAdmin('');
        setSocialData([]);
        setIsLogout(true);
        setIsLogin(false);
    }

    return (
        <header className="header">
            <div className="header__body">
                <div className="header__burger">
                    <span></span>
                </div>
                <nav className="header__menu menu">
                    <ul className="header__list">
                        <li>
                            <a href="" className="menu__link">где</a>
                        </li>
                    </ul>
                    <ul className="header__list">
                        <li>
                            <a href="" className="menu__link">история</a>
                        </li>
                    </ul>
                    <ul className="header__list">
                        <li>
                            <a href="" className="menu__link">приглашение</a>
                        </li>
                    </ul>
                    <ul className="header__list">
                        <li>
                            <a href="" className="menu__link">важно</a>
                        </li>
                    </ul>
                    <ul className="header__list">
                        <li>
                            {isAdmin ? <a href="" className="menu__link">гости</a> :
                                moment ? (<a href="" className="menu__link">трансляция</a>) :
                                    isLogin ? (
                                        <a href="" className="menu__link">карта</a>) :
                                        (<a href="" className="menu__link">мой ответ</a>)
                            }
                        </li>
                    </ul>
                </nav>
                {isLogin
                    ?
                    <div className="logout_block">
                        <span className="logout_span" onClick={accLogout}>Выйти</span>
                    </div>
                    :
                    null
                }
            </div>
        </header>
    );
};

export default Header;
