import React, { useState, useEffect } from 'react';
import './../styles/Plan.css';

const Plan = () => {
    const points = [
        { time: "10 : 00", name: "Венчание" },
        { time: "11 : 00", name: "Фото вам" },
        { time: "12 : 00", name: "Фотосессия нам" },
        { time: "14 : 30", name: "Пир" },
        { time: "18 : 00", name: "Финал" }
    ];

    const [currentPoint, setCurrentPoint] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentPoint((prevPoint) => (prevPoint + 1) % points.length);
        }, 1250); // Каждая точка показывается 0.25 секунды
        return () => clearInterval(interval);
    }, [points.length]);

    return (
        <div className="point_list">
            <div className="point">
                <div className="time_event">
                    <span>{points[currentPoint].time}</span>
                </div>
                <div className="name_event">
                    <span>{points[currentPoint].name}</span>
                </div>
            </div>
        </div>
    );
};

export default Plan;