import React, {useContext, useEffect, useState} from 'react';
import classes from "./../styles/FioInput.module.css";
import Cookies from "js-cookie";
import {LoginContext} from "../context/LoginContext";
import axios from "axios";

const FioInput = ({
                      error, setError, setLoading, setNotification, hasFio,
                      setHasFio, hash, updateStage, fio, setFio
                  }) => {
    const {isLogin, setIsLogin, socialData, isLogout} = useContext((LoginContext));
    const [isCode, setIsCode] = useState('');

    // useEffect(() => {
    //     const ts = Cookies.get('user_status');
    //     if (ts === 'success') {
    //         setIsCode('success');
    //     }
    // }, []);

    useEffect(() => {
        if (isLogin) {
            console.log('isLogin14');
            const fioCookie = Cookies.get('user_fio');
            if (fioCookie) {
                setHasFio(fioCookie);
            }
        } else {
            const ts = Cookies.get('user_status');
            const tf = Cookies.get('user_fio');
            if (socialData.length !== 0) {
                console.log('socialData21', socialData, socialData.length);
                setHasFio('');
            } else if (ts === 'success') {
                setError('');
                if (tf) {
                    const fioCookie = Cookies.get('user_fio');
                    if (fioCookie) {
                        setHasFio(fioCookie);
                    }
                } else {
                    console.log('isCode32');
                }
            } else {
                console.log('else24', socialData, socialData.length, isCode);
                setHasFio('');
                updateStage(1);
                setIsLogin(false);
            }
        }
    }, [isLogin, isLogout, socialData]);

    useEffect(() => {
        if (socialData.iss) {
            setFio(`${socialData.family_name} ${socialData.given_name} ${socialData.email}`);
            // setHasFio(`${socialData.family_name} ${socialData.given_name}`);
        }
        if (socialData.auth_date) {
            setFio(`${socialData.last_name} ${socialData.first_name} ${socialData.id}${socialData.username}`);
            // setHasFio(`${socialData.last_name} ${socialData.first_name}`);
        }
        console.log('HASFIO33', hasFio, Boolean(hasFio));
        console.log('FIO34', fio, Boolean(fio));
    }, [socialData]);

    const handleFioChange = (e) => {
        const value = e.target.value;
        const spaceCount = (value.match(/\s/g) || []).length;
        const russianAlphabetRegex = /^[А-Яа-яёЁ\s]*$/; // Разрешаем буквы и пробелы
        // Проверяем, если значение соответствует регулярному выражению
        if (russianAlphabetRegex.test(value)) {
            if (fio.trim().length > 3) {
                setError('');
            }
            if (spaceCount > 2) {
                return
            }
            setFio(value);

            // Убираем ошибку, если длина строки больше 3 символов
            if (value.trim().length > 3) {
                setError('');
            }
        } else {
            setError('Разрешены только русские буквы');
        }
    };

    const handleFioSubmit = async (coming) => {
        let inside_fio = fio;
        if (hasFio) {
            inside_fio = hasFio;
        }
        if (inside_fio.trim().length < 4) {
            setError('Нам нужно знать ваше ФИО для подсчета количества');
        } else {
            setLoading(true);

            const [lastname, firstname, patronymic] = inside_fio.split(' ');

            try {
                await axios.patch(`https://sms.silenc.ru/api/phone/${hash}/`, {
                    firstname: firstname || '',
                    lastname: lastname || '',
                    patronymic: patronymic || '',
                    answer: coming.toString()
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                setNotification(coming ? 'Спасибо, до скорой встречи!)' : 'Спасибо за вашу реакцию');
                setTimeout(() => {
                    setNotification('');
                    setLoading(false);
                    Cookies.set('user_answer', coming.toString(), {expires: 180}); // Сохраняем ответ в куки на полгода (180 дней)
                }, 2000);
                setIsLogin(true);
            } catch (error) {
                setError('Ошибка при отправке данных. Попробуйте позже.');
                setTimeout(() => {
                    setNotification('');
                }, 2000);
            } finally {
                setNotification('');
                setLoading(false);
            }
        }
    };

    return (
        <div>
            {hasFio ? (
                <div>
                    <h1>Привет, {hasFio}</h1>
                    <p>Будем рады видеть тебя на нашем торжестве! ;)</p>
                    <p>Изменить ответ:</p>
                    <button onClick={() => handleFioSubmit(true)} className={classes.btn_send_sms}>Приду</button>
                    <button onClick={() => handleFioSubmit(false)} className={classes.btn_send_sms}>Не приду</button>
                    {error && <p className={classes.error}>{error}</p>}
                </div>
            ) : socialData ? (
                <div>
                    <h1>Проверьте ФИО</h1>
                    <input
                        type="text"
                        placeholder="Ваше ФИО"
                        value={fio}
                        className={classes.fio}
                        onChange={handleFioChange}
                    />
                    {error && <p className={classes.error}>{error}</p>}
                    <button onClick={() => handleFioSubmit(true)} className={classes.btn_send_sms}>Приду</button>
                    <button onClick={() => handleFioSubmit(false)} className={classes.btn_send_sms}>Не приду</button>
                </div>
            ) : (
                <div>
                    <h1>Введите ФИО</h1>
                    <input
                        type="text"
                        placeholder="Ваше ФИО"
                        value={fio}
                        className={classes.fio}
                        onChange={handleFioChange}
                    />
                    {error && <p className={classes.error}>{error}</p>}
                    <button onClick={() => handleFioSubmit(true)} className={classes.btn_send_sms}>Приду</button>
                    <button onClick={() => handleFioSubmit(false)} className={classes.btn_send_sms}>Не приду</button>
                </div>
            )}
        </div>
    );
};

export default FioInput;
