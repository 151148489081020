import React from 'react';
import './../styles/Map.css';
import Phone from "./Phone";


const Map = ({swiperRef}) => {

    return (
        <div className="map__content">
            <Phone swiperRef={swiperRef} />
        </div>
    );
};

export default Map;