import React, {useContext} from 'react';
import classes from "./../styles/SocialButtons.module.css";
import {GoogleLogin} from "@react-oauth/google";
import {jwtDecode} from "jwt-decode";
import TelegramLoginButton from "react-telegram-login";
import {LoginContext} from "../context/LoginContext";
import Cookies from "js-cookie";

const SocialButtons = ({setError, updateStage, setLoading, setHash, setFio}) => {
    const {setIsAdmin, setSocialData} = useContext(LoginContext);
    console.log(Cookies.get('sms_pack'));

    const handleGoogleSuccess = async (response) => {
        setLoading(true);
        const decoded = jwtDecode(response.credential);
        setSocialData(decoded);
        // Cookies.set('user_fio', `${socialData.family_name} ${socialData.given_name}`, {expires: 180});
        // setFio(`${decoded.given_name} ${decoded.family_name}`);
        await createNote(decoded);
    };

    const handleGoogleFailure = (response) => {
        setError('Не удалось получить данные от Google.');
    };

    const handleTelegramResponse = async response => {
        setLoading(true);
        setSocialData(response);
        console.log(response);
        // Cookies.set('user_fio', `${socialData.last_name} ${socialData.first_name}`, {expires: 180});
        await createNote(response);
    }

    const createNote = async (source) => {
        try {
            const qp = {
                number:  null, status: 'wait'
            }
            if (source.iss) {
                qp.number = `${source.email}`
            } else {
                qp.number = `${source.id}`
            }
            const response = await fetch('https://sms.silenc.ru/api/phone/', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json' // Указываем тип содержимого
                }, body: JSON.stringify(qp)
            });

            const answerNote = await response.json();
            setHash(answerNote.id);
            Cookies.set('user_phone', answerNote.number, {expires: 180})
            if (answerNote.status) {
                if (answerNote.lastname || answerNote.firstname || answerNote.patronymic) {
                    let nf = `${answerNote.lastname} ${answerNote.firstname} ${answerNote.patronymic}`.trim();
                    // setHasFio(nf);
                    Cookies.set('user_fio', nf, {expires: 180}); // Сохраняем fio в куки на полгода (180 дней)
                }
                if (answerNote.answer) {
                    Cookies.set('user_answer', String(answerNote.answer), {expires: 180})
                }
                // updateStage(2, 2);  // Переход к этапу ввода кода
            } else {
                setError('Ошибка при отправке. Попробуйте ещё раз.');
            }
        } catch (error) {
            setError(`Ошибка сети. Попробуйте ещё раз.${error}`);
        } finally {
            if (source.iss) {
                setFio(`${source.family_name} ${source.given_name}`);
            } else {
                setFio(`${source.last_name} ${source.first_name}`);
            }
            Cookies.set('user_status', 'success', {expires: 180});
            setLoading(false);
            updateStage(3);
        }
    }

    function openNW(e) {
        console.log(e.target);
        // Открываем новое окно с параметрами
        const authWindow = window.open(
            'https://webtelegram.oauthtx3zf.ru',  // URL страницы аутентификации
            '_blank',                    // Открыть в новой вкладке или окне
            'width=500,height=600'       // Задаем размеры окна
        );
        console.log('authWindow', authWindow);

        // Слушаем сообщение от страницы авторизации
        window.addEventListener('message', (event) => {
            if (event.origin === 'https://webtelegram.oauthtx3zf.ru') {  // Проверяем, что сообщение пришло с нужного домена
                if (event.data.includes('login_success')) {
                    console.log('Login successful! Returning to app...');
                    console.log(event);
                    Cookies.set('user_status', 'success', {expires: 180});
                    if (event.data.includes('9040270943') || event.data.includes('9657248968')) {
                        setIsAdmin(event.data.split(':')[1]);
                    }
                    setHash(event.data.split(':')[2]);
                    setError('');
                    updateStage(3);
                    // Логика для обработки успешного логина, например, редирект или обновление состояния
                }
            }
        });
    }

    return (<div className={classes.social}>
        {Cookies.get('sms_pack') ? <p>Войдите с помощью</p> : <p>Или войдите с помощью</p>}
        <div className={classes.btnBlock}>
            <div className={classes.socBtn}>
                <div className={classes.tg}>
                    {/*Telegram Login Button */}
                    <div className={classes.tgBtn}>
                        {Cookies.get('sms_pack') ?
                            <div className={classes.contSvg} onClick={openNW}>
                                <svg className={classes.smsPackBtn} xmlns="http://www.w3.org/2000/svg" width="40"
                                     height="40"
                                     viewBox="0 0 160 160">
                                    <path id="logo" fillRule="evenodd"
                                          d="M80,0 C124.18278,0 160,35.81722 160,80 C160,124.18278 124.18278,160 80,160 C35.81722,160 0,124.18278 0,80 C0,35.81722 35.81722,0 80,0 Z M114.262551,46.4516129 L114.123923,46.4516129 C111.089589,46.5056249 106.482806,48.0771432 85.1289541,56.93769 L81.4133571,58.4849956 C72.8664779,62.0684477 57.2607933,68.7965125 34.5963033,78.66919 C30.6591745,80.2345564 28.5967328,81.765936 28.4089783,83.2633288 C28.0626453,86.0254269 31.8703852,86.959903 36.7890378,88.5302703 L38.2642674,89.0045258 C42.3926354,90.314406 47.5534685,91.7248852 50.3250916,91.7847532 C52.9151948,91.8407003 55.7944784,90.8162976 58.9629426,88.7115451 L70.5121776,80.9327422 C85.6657026,70.7535853 93.6285785,65.5352892 94.4008055,65.277854 L94.6777873,65.216416 C95.1594319,65.1213105 95.7366278,65.0717596 96.1481181,65.4374337 C96.6344248,65.8695939 96.5866185,66.6880224 96.5351057,66.9075859 C96.127514,68.6448691 75.2839361,87.6143392 73.6629144,89.2417998 L73.312196,89.6016896 C68.7645143,94.2254793 63.9030972,97.1721503 71.5637945,102.355193 L73.3593638,103.544598 C79.0660342,107.334968 82.9483395,110.083813 88.8107882,113.958377 L90.3875424,114.996094 C95.0654739,118.061953 98.7330313,121.697601 103.562866,121.253237 C105.740839,121.052855 107.989107,119.042224 109.175465,113.09692 L109.246762,112.727987 C112.002037,98.0012935 117.417883,66.09303 118.669527,52.9443975 C118.779187,51.7924073 118.641237,50.318088 118.530455,49.6708963 L118.474159,49.3781963 C118.341081,48.7651315 118.067967,48.0040758 117.346762,47.4189793 C116.412565,46.6610871 115.002114,46.4638844 114.262551,46.4516129 Z"></path>
                                </svg>
                            </div>
                            :
                            <TelegramLoginButton
                                dataOnauth={handleTelegramResponse}
                                botName="WeChatDbot"
                                usePic={false}
                            />
                        }
                    </div>
                </div>
                {Cookies.get('sms_pack') ? null : (
                    <div className={classes.gmail}>
                        <div className={classes.gmBtn}>
                            <GoogleLogin
                                onSuccess={handleGoogleSuccess}
                                onError={handleGoogleFailure}
                                type={"icon"}
                                size={"medium"}
                                width={32}
                                shape={"circle"}
                                logo_alignment={"center"}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>);
};

export default SocialButtons;
