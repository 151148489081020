import React, {useState, useEffect, useContext} from 'react';
import '../styles/CountdownTimer.css';
import {LoginContext} from "../context/LoginContext";

// Функция для склонения числительных
const declension = (number, one, few, many) => {
    const n = Math.abs(number) % 100;
    const n1 = n % 10;

    if (n > 10 && n < 20) {
        return many;
    }
    if (n1 > 1 && n1 < 5) {
        return few;
    }
    if (n1 === 1) {
        return one;
    }
    return many;
};

const CountdownTimer = ({ targetDate }) => {
    const {setMoment} = useContext(LoginContext);
    const calculateTimeLeft = () => {
        const difference = +new Date(targetDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                дни: Math.floor(difference / (1000 * 60 * 60 * 24)),
                часы: Math.floor((difference / (1000 * 60 * 60)) % 24),
                минуты: Math.floor((difference / 1000 / 60) % 60),
                секунды: Math.floor((difference / 1000) % 60),
            };
        } else {
            return 0;
        }
        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const checkTimeLeft = () => {
            let calc = calculateTimeLeft();
            if (calc === 0) {
                setMoment(true);
            } else {
                setTimeLeft(calc);
            }
        };

        checkTimeLeft(); // Проверяем время сразу при рендере

        const timer = setTimeout(() => {
            checkTimeLeft();
        }, 1000);

        return () => clearTimeout(timer); // Чистим таймер при размонтировании компонента
    }, [timeLeft, setMoment]);

    return (
        <div className="countdown">
            {Object.keys(timeLeft).map((interval, index) => {
                const value = timeLeft[interval];
                let label;

                switch (interval) {
                    case 'дни':
                        label = declension(value, 'день', 'дня', 'дней');
                        break;
                    case 'часы':
                        label = declension(value, 'час', 'часа', 'часов');
                        break;
                    case 'минуты':
                        label = declension(value, 'минута', 'минуты', 'минут');
                        break;
                    case 'секунды':
                        label = declension(value, 'секунда', 'секунды', 'секунд');
                        break;
                    default:
                        label = interval;
                }

                return (
                    <div key={index} className="time-segment">
                        <span className="time">{value} </span>
                        <span className="label">{label}</span>
                    </div>
                );
            })}
        </div>
    );
};

export default CountdownTimer;
