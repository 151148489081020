import React, {useContext, useEffect, useState} from 'react';
import classes from "./../styles/CodeInput.module.css";
import Cookies from "js-cookie";
import {LoginContext} from "../context/LoginContext";
import {type} from "@testing-library/user-event/dist/type";
import SocialButtons from "./SocialButtons";

const CodeInput = ({
                       error, setError, stage, setLoading, handlePhoneSubmit,
                       handleResetPhone, updateStage, setHash, setFio
                   }) => {
    const {_, setIsLogin} = useContext(LoginContext);
    const [isCodeValid, setIsCodeValid] = useState(false);
    const [code, setCode] = useState('');
    const [number, setNumber] = useState('');
    const [timeLeft, setTimeLeft] = useState(107997);

    useEffect(() => {
        const un = Cookies.get('user_phone');
        if (un) {
            setNumber(un);
        }
    }, []);

    useEffect(() => {
        if (stage === 2) {
            // Устанавливаем таймер для кода
            const expirationTime = 2 * 60 * 1000; // 2 минуты
            const timer = setInterval(() => {
                setTimeLeft(expirationTime - (Date.now() - Cookies.get('auth_stage_time')));
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [stage]);

    const formatTime = (milliseconds) => {
        const totalSeconds = Math.max(Math.ceil(milliseconds / 1000), 0);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;

        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleCodeChange = (e) => {
        const value = e.target.value;

        // Разрешить только цифры
        if (/^\d*$/.test(value) && value.length <= 5) {
            setCode(value);
        }

        // Меняем состояние в зависимости от длины номера
        if (value.length < 5) {
            setIsCodeValid(false); // Код невалиден, если меньше 5 символов
        } else if (value.length === 5) {
            setError('');
            setIsCodeValid(true);  // Код валиден, если 5 символов
        }
    };

    const handleCodeSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        if (isCodeValid) {
            try {
                const response = await fetch('https://sms.silenc.ru/api/verify/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({phone: `+7${number}`, code})
                });

                const answerCode = await response.json();
                if (answerCode.status === 'success') {
                    Cookies.set('user_status', answerCode.status, {expires: 180});
                    updateStage(3);
                } else {
                    setError('Неверный код. Попробуйте ещё раз.');
                }
            } catch (error) {
                setError('Ошибка сети');
            } finally {
                setLoading(false);
            }
        } else {
            setError('Неверный код');
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleCodeSubmit}>
            <h1>Введите код из смс</h1>
            <input
                type="text"
                placeholder="Введите код"
                value={code}
                className={`${classes.code} ${isCodeValid ? classes['validLenght'] : ''}`}
                onChange={handleCodeChange}
            />
            {error && <p className={classes.error}>{error}</p>}
            <p>
                {timeLeft > 10000 ? (
                    formatTime(timeLeft)
                ) : (
                    <span className={classes.repeatCode} onClick={handlePhoneSubmit}>запросить код ещё раз</span>
                )}
            </p>
            <button type="submit" className={classes.btnSendSms}>Войти на сайт</button>
            <a href="#" onClick={handleResetPhone} className={classes.changePhoneA}>
                Изменить номер
            </a>
            <SocialButtons setError={setError} updateStage={updateStage}
                           setLoading={setLoading} setHash={setHash} setFio={setFio}/>
        </form>
    );
};

export default CodeInput;
