import React from 'react';
import './../styles/WhenComp.css';
import CountdownTimer from "./CountdownTimer";
import FeedBackBtn from "./FeedBackBtn";

const WhenComp = ({ swiperRef }) => {

    return (
        <div className={"whenPageClass"}>
            <div className={"when__content"}>
                <h1 className={"when__title"}>
                    <span className={"nik"}>Хусрав  </span>
                    <span className={"amp"}>&  </span>
                    <span className={"nik"}>О<span className="custom-font">к</span>сана</span>
                </h1>
            </div>
            <CountdownTimer targetDate={"2024-10-05T00:00:00"}/>
            {/*<div className="dtt">*/}
            {/*    <span className="temptext">Форма для отклика будет добавлена в среду,кнопку временно отключили, просьба подождать до среды;)</span>*/}
            {/*</div>*/}
            <FeedBackBtn swiperRef={swiperRef} />
        </div>
    );
};

export default WhenComp;
