import React from 'react';
import './../styles/Dress.css';
import redDress from './../assets/red.jpg';
import birusDress from './../assets/birus.jpg';
import orangeDress from './../assets/orange.jpg';

const Dress = ({ color_name }) => {
    let colorVar;

    // Используем switch-case для выбора изображения на основе color_name
    switch (color_name) {
        case 'red':
            colorVar = redDress;
            break;
        case 'birus':
            colorVar = birusDress;
            break;
        case 'orange':
            colorVar = orangeDress;
            break;
        default:
            colorVar = redDress; // По умолчанию красное платье
            break;
    }

    return (
        <div className="dress__content">
            <div className="dress_wrap">
                <img src={colorVar} alt={color_name} className="dress_img"/>
            </div>
        </div>
    );
};

export default Dress;
